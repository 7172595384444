
window.scrollToTeam = () => {
  document.querySelector('.team-container').scrollIntoView({ behavior: 'smooth' })
}

window.scrollToServices = () => {
  document.querySelector('.services').scrollIntoView({ behavior: 'smooth' })
}

window.scrollToReviews = () => {
  document.querySelector('.reviews').scrollIntoView({ behavior: 'smooth' })
}

window.scrollToContact = () => {
  document.querySelector('footer').scrollIntoView({ behavior: 'smooth' })
}

function showLoading() {
  document.getElementById('contact-loading').style.display = 'inherit'
  document.getElementById('send-button').style.display = 'none'
}

function showSendButtonAndOkMessage() {
  MicroModal.show('modal-success-contact')
  document.getElementById('contact-loading').style.display = 'none'
  document.getElementById('send-button').style.display = 'inherit'
}

function showError() {
  MicroModal.show('modal-error-contact')
  document.getElementById('contact-loading').style.display = 'none'
  document.getElementById('send-button').style.display = 'inherit'
}

function checkEmail(email) {
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email)
}

window.sendContactMail = () => {
  const email = document.getElementById('contact-email').value
  const message = document.getElementById('contact-message').value
  if (!checkEmail(email)) {
    MicroModal.show('modal-email-contact')
    return
  }
  if (message.length < 1) {
    MicroModal.show('modal-message-contact')
    return
  }
  showLoading()
  grecaptcha.ready(function() {
    grecaptcha.execute('6Lchdc4UAAAAAD_RAXsjeINo2ttNSs-QiqV-n4-2', {action: 'homepage'}).then(function(recaptchaToken) {
      axios.post('https://us-central1-orion-yacht-support.cloudfunctions.net/messages', {
        email,
        message,
        recaptchaToken,
      }).then((response) => {
        if (response.status === 200) {
          showSendButtonAndOkMessage()
        } else {
          showError()
        }
      }).catch(() => showError())
    })
  })
}

window.addEventListener('load', function () { // wait for document ready
  MicroModal.init({
    onShow: modal => console.info(`${modal.id} is shown`), // [1]
    onClose: modal => console.info(`${modal.id} is hidden`), // [2]
    openTrigger: 'data-custom-open', // [3]
    closeTrigger: 'data-custom-close', // [4]
    disableScroll: true, // [5]
    disableFocus: false, // [6]
    awaitOpenAnimation: false, // [7]
    awaitCloseAnimation: false, // [8]
    debugMode: true // [9]
  })

  window.servicesSlider = tns({
    container: '.services .carousel',
    items: 1,
    loop: true,
    controls: false,
    nav: false,
    responsive: {
      0: {
        disable: true,
      },
      800: {
        disable: false,
      }
    }
  })
});
